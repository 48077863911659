import { SCROLL_KEYS } from '../const/locomotiveScroll.const';
import getWindowSize from './getWindow.util';
import { isScrollEnabled } from './isScrollEnabled.util';

export const checkIsInview = (args: any, key: SCROLL_KEYS): boolean => {
	if (!isScrollEnabled()) {
		const element = document.querySelector(`[data-scroll-id=${key}]`);
		const sizes = getWindowSize();

		if (!element || !sizes) {
			return false;
		}

		const bounding = element?.getBoundingClientRect();

		if (!bounding) {
			return false;
		}

		return bounding.top < sizes.height && bounding.bottom > 0;
	}
	return typeof args.currentElements[key] === 'object';
};
