import React, {
	ReactElement,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from 'react';

import { graphql } from 'gatsby';
import gsap from 'gsap';

import {
	Hero,
	Pagination,
	RichText,
	ContentBlock,
	ITabItem,
	Tabs,
	Header,
	SEO,
} from '../components';
import { ANIMATION_HELPERS } from '../const/animations.const';
import { CONTENT_BLOCK_TYPES } from '../const/data.const';
import { SCROLL_KEYS } from '../const/locomotiveScroll.const';
import { ROUTES } from '../const/routes.const';
import { IAppContext } from '../context/app/app.types';
import { ILocomotiveScrollContext } from '../context/locomotiveScroll/locomotiveScroll.types';
import { useAppContext } from '../hooks/context/appContext.hook';
import { useLocomotiveScrollContext } from '../hooks/context/locomotiveScroll.hook';
import { ICollection, ICollectionCategory } from '../types/collection.types';
import { IContentBlock } from '../types/data.types';
import { checkIsInview } from '../utils/checkIsInview.util';
import { debounce } from '../utils/debounce.util';
import { getPerf } from '../utils/detectPerf.util';
import { isScrollEnabled } from '../utils/isScrollEnabled.util';

export interface ICollectionProps {
	data: any;
	pageContext: any;
	location: any;
}

export const Collection: React.FC<ICollectionProps> = ({
	data,
	pageContext,
	location,
}: ICollectionProps): ReactElement<any, any> | null => {
	const [shouldPlayVideo, setShouldPlayVideo] = useState<boolean>(false);
	const { scroll }: ILocomotiveScrollContext = useLocomotiveScrollContext();

	const { setIsGridVisible, setIsMenuButtonDark, setIsAnimating }: IAppContext =
		useAppContext();

	const collection: ICollection | undefined = data?.craft?.entry;
	const isGridImage = location?.state?.isGridImage;
	const perf = getPerf();

	/**
	 * HOOKS
	 */
	const handleScroll = useCallback(
		(args) => {
			setShouldPlayVideo(checkIsInview(args, SCROLL_KEYS.VIDEO));
			setIsMenuButtonDark(args.scroll.y > 60);
		},
		[setIsMenuButtonDark],
	);

	const scrollToCategory = (id: string): void => {
		if (!isScrollEnabled()) {
			if (document) {
				document.getElementById(`category-${id}`)?.scrollIntoView();
			}
		} else if (scroll) {
			scroll.scrollTo(`#category-${id}`);
		}
	};

	const handleAnimation = useCallback(
		(delay = 0, delayLarge = 0) => {
			setIsAnimating(true);

			gsap.fromTo(
				'.c-logo',
				{
					opacity: 0,
				},
				{
					opacity: 1,
					duration: ANIMATION_HELPERS.duration,
					ease: ANIMATION_HELPERS.ease,
					delay,
				},
			);

			gsap.fromTo(
				'.c-hero__title',
				{
					opacity: 0,
				},
				{
					opacity: 1,
					duration: ANIMATION_HELPERS.duration,
					ease: ANIMATION_HELPERS.ease,
					delay,
				},
			);

			gsap.fromTo(
				'.c-hero__title',
				{
					letterSpacing: 30,
				},
				{
					letterSpacing: 0.4,
					duration: ANIMATION_HELPERS.durationLarge,
					ease: ANIMATION_HELPERS.ease,
					delay,
				},
			);

			if (delayLarge) {
				gsap.fromTo(
					'.c-hero__image',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						duration: ANIMATION_HELPERS.duration,
						ease: ANIMATION_HELPERS.ease,
					},
				);
			}
		},
		[setIsAnimating],
	);

	useEffect(() => {
		if (!isScrollEnabled()) {
			// Info(Katia): Fallback for low performance
			if (typeof window !== 'undefined') {
				window.addEventListener('scroll', (e) => {
					debounce(() => {
						const args = {
							scroll: {
								y: window.scrollY,
							},
						};
						handleScroll(args);
					}, 50)();
				});
			}
			return;
		}

		if (!scroll) {
			return;
		}

		scroll.on('scroll', (args: any) => {
			if (args.scroll.y < 1) {
				return;
			}

			handleScroll(args);
		});
	}, [scroll, handleScroll, perf]);

	useEffect(() => {
		setIsMenuButtonDark(false);

		return () => {
			setIsMenuButtonDark(false);
			gsap.killTweensOf('.c-hero__image');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isGridImage) {
			setIsGridVisible(true);
			handleAnimation(1.2, 3);

			window.history.replaceState(location.pathname, '');
			return;
		}

		setIsGridVisible(false);
		handleAnimation();
	}, [setIsGridVisible, isGridImage, handleAnimation, location.pathname]);

	if (!collection) {
		return null;
	}

	const { title, hero, description, children, seomatic } = collection;
	const { next, prev } = pageContext;

	/**
	 * RENDER
	 */
	const renderCategories = (categories: ICollectionCategory[]): ReactNode => {
		const tabs: ITabItem[] = categories.map((category: ICollectionCategory) => ({
			id: category.id,
			label: category.title,
		}));

		return <Tabs tabs={tabs} onClickTab={scrollToCategory} />;
	};

	return (
		<div>
			<SEO {...seomatic} />

			<div className="p-collection__header" data-scroll-section>
				<Header disableTransition />
			</div>

			<main className="u-stack">
				<section data-scroll-section>
					<Hero title={title} image={hero[0]} hasImage={!isGridImage} />
				</section>
				<section
					className="c-navigation-sub__wrapper"
					data-scroll-section
					id="subnav-target">
					<div className="u-stack">
						<header
							className="c-navigation-sub u-flex u-jc-between"
							data-scroll
							data-scroll-sticky
							data-scroll-target="#subnav-target">
							<h3>{title}</h3>
							{renderCategories(children)}
						</header>
						{description && (
							<RichText
								className="u-font-s-sm p-collection__description"
								content={description}
							/>
						)}
						{children?.map(
							({ contentBlocks, id, title }: ICollectionCategory) => (
								<section key={id} id={`category-${id}`}>
									<h4 className="u-font-s-sm u-letter-spacing-0">
										{title}
									</h4>
									{contentBlocks.map((data: IContentBlock) => {
										const isImages =
											data.typeHandle ===
												CONTENT_BLOCK_TYPES.IMAGES &&
											data.images &&
											data.images.length > 0;

										if (isImages) {
											const imageData = {
												...data,
												srcsetSizes: [1440, 1024, 800, 400],
												sizes: '(min-width: 720px) 50vw, (min-width: 990px) 30vw, 100vw',
											};

											return (
												<ContentBlock
													data={imageData}
													key={data.id}
													shouldPlayVideo={shouldPlayVideo}
												/>
											);
										}
										return (
											<ContentBlock
												data={data}
												key={data.id}
												shouldPlayVideo={shouldPlayVideo}
											/>
										);
									})}
								</section>
							),
						)}
					</div>
				</section>
			</main>

			<footer data-scroll-section>
				<Pagination next={next} prev={prev} prefix={ROUTES.COLLECTIONS} />
			</footer>
		</div>
	);
};

export default Collection;

export const collectionBySlug = graphql`
	query getCollectionBySlug($slug: [String]) {
		craft {
			entry(slug: $slug) {
				... on Craft_collections_collection_Entry {
					id
					title
					description
					seomatic {
						metaTitleContainer
						metaTagContainer
						metaLinkContainer
						metaJsonLdContainer
					}
					hero(limit: 1) {
						... on Craft_assetsAws_Asset {
							id
							title
							hasFocalPoint
							focalPoint
							width
							height
							url
							urlWebp1920: url(width: 1920, format: "webp")
							urlWebp1440: url(width: 1440, format: "webp")
							urlWebp1024: url(width: 1024, format: "webp")
							urlWebp800: url(width: 800, format: "webp")
							urlWebp400: url(width: 400, format: "webp")
							urlJpg1920: url(width: 1920, format: "jpg")
							urlJpg1440: url(width: 1440, format: "jpg")
							urlJpg1024: url(width: 1024, format: "jpg")
							urlJpg800: url(width: 800, format: "jpg")
							urlJpg400: url(width: 400, format: "jpg")
						}
					}
					children {
						id
						title
						... on Craft_collections_collection_Entry {
							contentBlocks {
								... on Craft_contentBlocks_images_BlockType {
									id
									typeHandle
									images {
										id
										... on Craft_assetsAws_Asset {
											title
											url
											width
											height
											urlWebp1440: url(
												width: 1440
												format: "webp"
											)
											urlWebp1024: url(
												width: 1024
												format: "webp"
											)
											urlWebp800: url(
												width: 800
												format: "webp"
											)
											urlWebp400: url(
												width: 400
												format: "webp"
											)
											urlJpg1440: url(
												width: 1440
												format: "jpg"
											)
											urlJpg1024: url(
												width: 1024
												format: "jpg"
											)
											urlJpg800: url(width: 800, format: "jpg")
											urlJpg400: url(width: 400, format: "jpg")
										}
									}
								}
								... on Craft_contentBlocks_description_BlockType {
									id
									typeHandle
									description
								}
								... on Craft_contentBlocks_video_BlockType {
									id
									typeHandle
									videoId
								}
							}
						}
					}
				}
			}
		}
	}
`;
